// Gatsby supports TypeScript natively!
import React, { useRef } from "react"
import { PageProps, Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/full-layout"
import SEO from "../components/seo"
import { rhythm } from "../utils/typography"
import Paper from '@material-ui/core/Paper'
import Image from "gatsby-image"

/*
#51255e = purple
#c8514b = red/orange
#f4cd62 = yellow

dark blue = #1c2b3d
real dark blue = #0f2034
light blue = #3c4b62
*/


type Data = {
  site: {
    siteMetadata: {
      title: string
    }
  }
  allMarkdownRemark: {
    edges: {
      node: {
        excerpt: string
        frontmatter: {
          title: string
          date: string
          description: string
        }
        fields: {
          slug: string
        }
      }
    }[]
  }
}

const BlogIndex = ({ data, location }: PageProps<Data>) => {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMarkdownRemark.edges
  return (
        <Layout location={location} title={siteTitle}>
          {/* <div style={{width: 400, height: 400, background: `url(${data.hero_image.childImageSharp.fixed})`}}></div> */}
          <SEO title="Dev Internship Dotcom" />
          <h3 style={{marginTop: '1.5rem', marginBottom: '.75rem', marginLeft: 16}}>How It Works</h3>
          <div style={{display: 'flex', flexWrap: 'wrap'}}>
            <div style={{flex: 1, minWidth: 250, margin: 16}}>
              <Paper elevation={3} style={{padding: 16, height: '100%'}}>
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                  <div style={{backgroundColor: 'rgb(58, 202, 227)', borderRadius: '50%', overflow: 'visible', width: 250, height: 250, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <Image
                  style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                  fixed={data.one.childImageSharp.fixed}
                />
                </div>
                </div>
                <div style={{fontWeight: 'bold', marginTop: 18}}>Not a Course</div>
                Don't just watch videos or work on snippets, get real experience working as a software engineer. Work side by side with talented engineers on production projects asynchronsously through a combination of videos, community, and one of a kind immersive work experiences. 
              </Paper>
            </div>
            <div style={{flex: 1, minWidth: 250, margin: 16}}>
            <Paper elevation={3} style={{padding: 16, height: '100%'}}>
              <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <Image
                  fixed={data.five.childImageSharp.fixed}
                />
                </div>
                <div style={{fontWeight: 'bold', marginTop: 18}}>Immersive Experience</div>
              Watch videos for free OR sign up for the full internship experience and work on production applications. Dev Internship Dotcom is the only online experience designed to simulate an actual internship.</Paper>
            </div>
          </div>
          <h3 style={{marginTop: '1.5rem', marginBottom: '.75rem', marginLeft: 16}}>Who This Is For</h3>
          <div style={{display: 'flex', flexWrap: 'wrap'}}>
            <div style={{flex: 1, minWidth: 250, margin: 16}}>
              <Paper elevation={3} style={{padding: 16, height: '100%'}}>
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <div style={{backgroundColor: 'rgb(58, 202, 227)', borderRadius: '50%', overflow: 'visible', width: 250, height: 250, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>

                <Image
                  style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                  fixed={data.three.childImageSharp.fixed}
                />
                </div>
                </div>
                <div style={{fontWeight: 'bold', marginTop: 18}}>Differnet Approach</div>
                Everyone learns differently. Whether you are self-taught, a bootcamp grad, a university student, or even a long time engineer with employement history, we believe that Dev Internship Dotcom offers a unique learning experience that can benefit you.
              </Paper>
            </div>
            <div style={{flex: 1, minWidth: 250, margin: 16}}>
            <Paper elevation={3} style={{padding: 16, height: '100%'}}>
              <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <Image
                  fixed={data.four.childImageSharp.fixed}
                />
                </div>
                <div style={{fontWeight: 'bold', marginTop: 18}}>Not Just Code</div>
                We've designed the experience to feel like a real internship at a startup and we've found people of all backgrounds and experience levels have benefited from our unique approach. That being said, we make a point to integrate discussion of business and career principles with our technical material.
              </Paper>
            </div>
            <div style={{flex: 1, minWidth: 250, margin: 16}}>
            <Paper elevation={3} style={{padding: 16, height: '100%'}}>
              <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <div style={{backgroundColor: 'rgb(58, 202, 227)', borderRadius: '50%', overflow: 'visible', width: 250, height: 250, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>

                <Image
                  fixed={data.two.childImageSharp.fixed}
                />
                </div>
                </div>
                <div style={{fontWeight: 'bold', marginTop: 18}}>Not an Intro</div>
                While everyone learns differently, our current internships are not designed for absolute beginners. We'll welcome anyone who is up for the challenge, and have plenty of recommendations for other resources, but people with at least a basic understanding of javascript are the most successful.
              </Paper>
            </div>
          </div>
          <h3 style={{marginTop: '1.5rem', marginBottom: '.75rem', marginLeft: 16}}>Subject Matter</h3>
          <div style={{display: 'flex', flexWrap: 'wrap'}}>

          <Paper elevation={3} style={{flex: 1, padding: 16, minWidth: 250, margin: 16}}>
          <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <Image
                  style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                  fixed={data.six.childImageSharp.fixed}
                />
                </div>
                <div style={{fontWeight: 'bold', marginTop: 18}}>Technology</div>
                Each internship focuses on a specific real world application and technology set. Our initial course focuses on front end technology, specifically the React ecosystem, but the approach/business/career concepts covered are universally applicable.</Paper>
          <Paper elevation={3} style={{flex: 1, padding: 16, minWidth: 250, margin: 16}}>
          <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <div style={{backgroundColor: 'rgb(58, 202, 227)', borderRadius: '50%', overflow: 'visible', width: 250, height: 250, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>

                <Image
                  style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                  fixed={data.seven.childImageSharp.fixed}
                />
                </div>
                </div>
                <div style={{fontWeight: 'bold', marginTop: 18}}>Business</div>
Being a successful software engineer, is more than just knowing the syntax of a programming language. Modern software engineers need to be understanding and engaged in their business to ensure they are making the biggest impact possible.</Paper>
          <Paper elevation={3} style={{flex: 1, padding: 16, minWidth: 250, margin: 16}}>
          <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <div style={{backgroundColor: 'rgb(58, 202, 227)', borderRadius: '50%', overflow: 'visible', width: 250, height: 250, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>

                <Image
                  style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                  fixed={data.eight.childImageSharp.fixed}
                />
                </div>
                </div>
                <div style={{fontWeight: 'bold', marginTop: 18}}>Process/Communication</div>
Our internships are run exactly how engineering departments function (at least the ones we've personally run/been a part of) so that you can learn to operate within that framework. So much of being an engineer is knowing how to speak the language.</Paper>
          <Paper elevation={3} style={{flex: 1, padding: 16, minWidth: 250, margin: 16}}>
          <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <Image
                  style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                  fixed={data.nine.childImageSharp.fixed}
                />
                </div>                <div style={{fontWeight: 'bold', marginTop: 18}}>Career Advice</div>
                Everyone's career and circumstances will look differently, but there are certain universal principles we've learned that this internship is designed to pass onto you. We're also not shy about sharing less universal advice that you may or may learn from.
</Paper>
          </div>
          <h3 style={{marginTop: '1.5rem', marginBottom: '.75rem', marginLeft: 16}}>Immersive Difference </h3>
          <div style={{display: 'flex', flexWrap: 'wrap'}}>

          <Paper elevation={3} style={{flex: 1, padding: 16, minWidth: 250, margin: 16}}>
          <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <Image
                  style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                  fixed={data.ten.childImageSharp.fixed}
                />
                </div><div style={{fontWeight: 'bold', marginTop: 18}}>Internship Environment</div>
                On day one of your internship you'll be assigned a company email, given code and course access, and be assigned tasks just as you would in any other job. We'll use these resources throughout your internship, just as we communicate with the interns we've mentored in the past in our careers.
                </Paper>
          <Paper elevation={3} style={{flex: 1, padding: 16, minWidth: 250, margin: 16}}>
          <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <div style={{backgroundColor: 'rgb(58, 202, 227)', borderRadius: '50%', overflow: 'visible', width: 250, height: 250, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>

                <Image
                  style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                  fixed={data.eleven.childImageSharp.fixed}
                />
                </div>
                </div><div style={{fontWeight: 'bold', marginTop: 18}}>Responsive Code Reviews</div>
                As you work on adding code to grow our product and business, your code will be automatically reviewed and tasks assigned ensure quality.
                </Paper>
          <Paper elevation={3} style={{flex: 1, padding: 16, minWidth: 250, margin: 16}}>
          <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <div style={{backgroundColor: 'rgb(58, 202, 227)', borderRadius: '50%', overflow: 'visible', width: 250, height: 250, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>

                <Image
                  style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                  fixed={data.twelve.childImageSharp.fixed}
                />
                </div>
                </div><div style={{fontWeight: 'bold', marginTop: 18}}>Task Management</div>
                Modern development organizations use tools to manage the work they and their team are working on. We'll talk about strategies to do this, and also employ them ourselves throughout your internship.
                </Paper>
          <Paper elevation={3} style={{flex: 1, padding: 16, minWidth: 250, margin: 16}}>
          <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 24}}>
          <div style={{backgroundColor: 'rgb(58, 202, 227)', borderRadius: '50%', overflow: 'visible', width: 250, height: 250, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>

                <Image
                  style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                  fixed={data.thirteen.childImageSharp.fixed}
                />
                </div>
                </div><div style={{fontWeight: 'bold', marginTop: 18}}>Production Application</div>
                Unlike standard courses, we'll be working on a single product throughout your internship. This is an actual product that exists with paying customers and you'll be working on the actual code base. However, we don't believe in free labor and to honor that, we'll only use the code we write together in videos in production.
                </Paper>
          </div>
          <h3 style={{marginTop: '1.5rem', marginBottom: '.75rem', marginLeft: 16}}>Results</h3>
          <div style={{display: 'flex', flexWrap: 'wrap'}}>

          <Paper elevation={3} style={{flex: 1, padding: 16, minWidth: 250, margin: 16}}>
          <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <Image
                  style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                  fixed={data.fourteen.childImageSharp.fixed}
                />
                </div><div style={{fontWeight: 'bold', marginTop: 18}}>Become a Better Engineer</div>
                Ultimately, internships are designed to help people learn and grow. That's why we've built this unique experience. We want to help people become better engineers and accomplish their personal goals.
                </Paper>
          <Paper elevation={3} style={{flex: 1, padding: 16, minWidth: 250, margin: 16}}>
          <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <div style={{backgroundColor: 'rgb(58, 202, 227)', borderRadius: '50%', overflow: 'visible', width: 250, height: 250, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>

                <Image
                  style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                  fixed={data.fifteen.childImageSharp.fixed}
                />
                </div>
                </div><div style={{fontWeight: 'bold', marginTop: 18}}>Hard and Soft Skills</div>
                Not every piece of advice, line of code, insights, ETC will help you, but overall we've found there's something that even the most experienced engineers can learn from working together. Our internships are designed to help grow the (arguably) even more valuable soft skills</Paper>
          <Paper elevation={3} style={{flex: 1, padding: 16, minWidth: 250, margin: 16}}>
          <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <div style={{backgroundColor: 'rgb(58, 202, 227)', borderRadius: '50%', overflow: 'visible', width: 250, height: 250, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>

                <Image
                  style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                  fixed={data.sixteen.childImageSharp.fixed}
                />
                </div>
                </div><div style={{fontWeight: 'bold', marginTop: 18}}>Gain Experience</div>
                While our internships can benefit everyone, we're particulary aiming to help people get started in the software industry. We know how are competitive entry level positions can be, our goal is to help give you an edge.
                </Paper>
          <Paper elevation={3} style={{flex: 1, padding: 16, minWidth: 250, margin: 16}}>
          <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <div style={{backgroundColor: 'rgb(58, 202, 227)', borderRadius: '50%', overflow: 'visible', width: 250, height: 250, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>

                <Image
                  style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                  fixed={data.seventeen.childImageSharp.fixed}
                />
                </div>
                </div><div style={{fontWeight: 'bold', marginTop: 18}}>Join a Community</div>
                We reject the stereotypes of software engineers being personality-less coders. We're normal people. And our different backgrounds bring valuable insights to a business. Together, we form a powerful community that can help each other and celebrate our wins.
                </Paper>
          </div>
        </Layout>

  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    one: file(absolutePath: { regex: "/web-design.png/" }) {
      childImageSharp {
        fixed(width: 250) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    two: file(absolutePath: { regex: "/work.png/" }) {
      childImageSharp {
        fixed(width: 250) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    three: file(absolutePath: { regex: "/analysissystem.png/" }) {
      childImageSharp {
        fixed(width: 250) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    four: file(absolutePath: { regex: "/bplan.png/" }) {
      childImageSharp {
        fixed(width: 250) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    five: file(absolutePath: { regex: "/pcoding.png/" }) {
      childImageSharp {
        fixed(width: 250) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    six: file(absolutePath: { regex: "/awebdesign.png/" }) {
      childImageSharp {
        fixed(width: 250) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    seven: file(absolutePath: { regex: "/business.png/" }) {
      childImageSharp {
        fixed(width: 250) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    eight: file(absolutePath: { regex: "/communication.png/" }) {
      childImageSharp {
        fixed(width: 250) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    nine: file(absolutePath: { regex: "/goals.png/" }) {
      childImageSharp {
        fixed(width: 250) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    ten: file(absolutePath: { regex: "/intern.png/" }) {
      childImageSharp {
        fixed(width: 250) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    eleven: file(absolutePath: { regex: "/service.png/" }) {
      childImageSharp {
        fixed(width: 250) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    twelve: file(absolutePath: { regex: "/exp.png/" }) {
      childImageSharp {
        fixed(width: 250) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    thirteen: file(absolutePath: { regex: "/prodapp.png/" }) {
      childImageSharp {
        fixed(width: 250) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    fourteen: file(absolutePath: { regex: "/education.png/" }) {
      childImageSharp {
        fixed(width: 250) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    fifteen: file(absolutePath: { regex: "/softskills.png/" }) {
      childImageSharp {
        fixed(width: 250) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    sixteen: file(absolutePath: { regex: "/speeaccess.png/" }) {
      childImageSharp {
        fixed(width: 250) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    seventeen: file(absolutePath: { regex: "/appdev.png/" }) {
      childImageSharp {
        fixed(width: 250) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`
